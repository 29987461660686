<template>
  <div v-loading="loading" class="shop-settings">
    <el-form
      :model="baseInfoForm"
      status-icon
      label-position="right"
      ref="baseInfoForm"
      label-width="280px"
      class="demo-ruleForm"
    >
      <!--基本信息-->
      <div class="base-info-item">
        <h4>实体店铺地址及分店信息设置</h4>
        <div>
          <el-form-item label="商户实体店铺地址：" prop="entity_province_id">
            <div>
              <!--              <en-region-picker-->
              <!--                :api="MixinRegionApi"-->
              <!--                @changed="handleChangeArea"-->
              <!--                :default="address"-->
              <!--              ></en-region-picker>-->
              <address-selector
                :value="address"
                @change="handleChangeArea"
              ></address-selector>
            </div>
          </el-form-item>
          <el-form-item label="详细地址：" prop="entity_addr">
            <el-input
              v-model="baseInfoForm.entity_addr"
              class="ipt-default width"
              placeholder="例: 中关村大街18号B座中关村互联网教育创新中心1901室"
            ></el-input>
          </el-form-item>
          <el-form-item label="实体店铺分店/连锁店信息：" prop="entity_intro">
            <quill-editor
              v-model="baseInfoForm.entity_intro"
              :options="editorOption"
            >
            </quill-editor>
            <!-- <el-input
              type="textarea"
              resize="none"
              :rows="3"
              :maxlength="200"
              style="width: 400px"
              v-model="baseInfoForm.entity_intro"
              placeholder="请输入实体店铺分店/连锁店信息,最多输入200字"
            ></el-input> -->
          </el-form-item>
          <el-form-item label="分店信息是否显示在商户首页：" prop="showList2">
            <div style="width: 600px">
              <el-radio
                v-model="baseInfoForm.showList2"
                :label="1"
                @change="showListChange"
              >是
              </el-radio
              >
              <el-radio
                v-model="baseInfoForm.showList2"
                :label="0"
                @change="showListChange"
              >否
              </el-radio
              >
            </div>
          </el-form-item>
        </div>
        <h4>店铺资质信息设置</h4>
        <div>
          <el-form-item label="店铺资质上传：" prop="shop_qualification">
            <div class="imgsBox">
              <div
                v-for="(item, index) in qualificationsImgs"
                :key="index"
                class="imgBox"
                style="height: 110px"
              >
                <img :src="item" class="photo"/>
                <img
                  src="/img/sctp@2x.png"
                  class="imgDel"
                  @click.stop="imgDel(index, 'qualifications')"
                />
              </div>
              <el-upload
                :action="MixinUploadApi"
                :show-file-list="false"
                :on-success="
                  (res, file) => upLoadImgSuccess(res, file, 'qualifications')
                "
                :before-upload="upLoadImgBefore"
                v-if="qualificationsImgs.length < 5"
              >
                <div class="uploadBox">
                  <i class="el-icon-plus avatar-uploader-icon"></i>
                  <div>上传图片</div>
                </div>
              </el-upload>
            </div>
            <span class="explain">*最多上5张</span>
            <span class="explain">*建议尺寸640*640像素</span>
          </el-form-item>
          <el-form-item label="商户资质是否显示在商户首页：" prop="showList0">
            <div style="width: 600px">
              <el-radio
                v-model="baseInfoForm.showList0"
                :label="1"
                @change="showListChange"
              >是
              </el-radio
              >
              <el-radio
                v-model="baseInfoForm.showList0"
                :label="0"
                @change="showListChange"
              >否
              </el-radio
              >
            </div>
          </el-form-item>
        </div>
        <h4>营业时间设置</h4>
        <div>
          <el-form-item label="工作日营业时间：" prop="workday_open">
            <div>
              <!-- <el-time-picker
                is-range
                v-model="businessHours"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                placeholder="选择时间范围"
                format="HH:mm"
                value-format="HH:mm"
                :picker-options="{
                  start: '00:00',
                  end: '23:59',
                }"
                @change="businessTime('business')"
              ></el-time-picker> -->
              <el-select clearable style="width: 65px;" v-model="businessHours.start_hour"
                         @change="businessTime('business', 'start')">
                <el-option v-for="(hour, index) in hourList" :key="index" :label="hour" :value="hour"></el-option>
              </el-select>
              :
              <el-select clearable style="width: 65px;" v-model="businessHours.start_minute"
                         @change="businessTime('business', 'start')">
                <el-option v-for="(minute, index) in minuteList" :key="index" :label="minute"
                           :value="minute"></el-option>
              </el-select>
              <span>至</span>
              <el-select clearable style="width: 65px;" v-model="businessHours.end_hour"
                         @change="businessTime('business', 'end')">
                <el-option v-for="(hour, index) in hourList" :key="index" :label="hour" :value="hour"></el-option>
              </el-select>
              :
              <el-select clearable style="width: 65px;" v-model="businessHours.end_minute"
                         @change="businessTime('business', 'end')">
                <el-option v-for="(minute, index) in minuteList" :key="index" :label="minute"
                           :value="minute"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item label="周末营业时间：" prop="weekend_open">
            <div>
              <!-- <el-time-picker
                is-range
                v-model="weekendHours"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                placeholder="选择时间范围"
                format="HH:mm"
                value-format="HH:mm"
                :picker-options="{
                  start: '00:00',
                  end: '23:59',
                }"
                @change="businessTime('weekend')"
              ></el-time-picker> -->
              <el-select clearable style="width: 65px;" v-model="weekendHours.start_hour"
                         @change="businessTime('weekend', 'start')">
                <el-option v-for="(hour, index) in hourList" :key="index" :label="hour" :value="hour"></el-option>
              </el-select>
              :
              <el-select clearable style="width: 65px;" v-model="weekendHours.start_minute"
                         @change="businessTime('weekend', 'start')">
                <el-option v-for="(minute, index) in minuteList" :key="index" :label="minute"
                           :value="minute"></el-option>
              </el-select>
              <span>至</span>
              <el-select clearable style="width: 65px;" v-model="weekendHours.end_hour"
                         @change="businessTime('weekend', 'end')">
                <el-option v-for="(hour, index) in hourList" :key="index" :label="hour" :value="hour"></el-option>
              </el-select>
              :
              <el-select clearable style="width: 65px;" v-model="weekendHours.end_minute"
                         @change="businessTime('weekend', 'end')">
                <el-option v-for="(minute, index) in minuteList" :key="index" :label="minute"
                           :value="minute"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item
            label="店铺营业时间是否显示在商户首页："
            prop="showList3"
          >
            <div style="width: 600px">
              <el-radio
                v-model="baseInfoForm.showList3"
                :label="1"
                @change="showListChange"
              >是
              </el-radio
              >
              <el-radio
                v-model="baseInfoForm.showList3"
                :label="0"
                @change="showListChange"
              >否
              </el-radio
              >
            </div>
          </el-form-item>
        </div>
        <h4>师资力量设置</h4>
        <div>
          <el-form-item label="导师展示：">
            <div class="imgsBox">
              <div
                v-for="(item, index) in teacherBox"
                :key="index"
                class="imgBox"
              >
                <img :src="item.teacher_pic" class="photo"/>
                <div class="edit" @click.stop="teacherAddAndEdit('edit', item)">
                  编辑
                </div>
                <img
                  src="/img/sctp@2x.png"
                  class="imgDel"
                  @click.stop="teacherAddAndEdit('del', item)"
                />
                <div class="teacherName">{{ item.teacher_name }}</div>
              </div>
              <div>
                <el-button
                  type="primary"
                  @click="teacherAddAndEdit('add')"
                  size="mini"
                >添加
                </el-button
                >
              </div>
            </div>
          </el-form-item>
          <el-form-item label="师资力量是否显示在商户首页：" prop="showList1">
            <div style="width: 600px">
              <el-radio
                v-model="baseInfoForm.showList1"
                :label="1"
                @change="showListChange"
              >是
              </el-radio
              >
              <el-radio
                v-model="baseInfoForm.showList1"
                :label="0"
                @change="showListChange"
              >否
              </el-radio
              >
            </div>
          </el-form-item>
        </div>
        <h4>其他信息设置</h4>
        <div>
          <el-form-item label="客服电话1：" prop="phone1">
            <el-input
              v-model="baseInfoForm.phone1"
              class="ipt-default width"
              placeholder="请输入第一个客服电话"
            ></el-input>
          </el-form-item>
          <el-form-item label="客服电话2：" prop="phone2">
            <el-input
              v-model="baseInfoForm.phone2"
              class="ipt-default width"
              placeholder="请输入第二个客服电话"
            ></el-input>
          </el-form-item>
          <el-form-item label="店铺LOGO：" prop="logo_url">
            <div>
              <el-upload
                :action="MixinUploadApi"
                :show-file-list="false"
                :on-success="(res, file) => upLoadImgSuccess(res, file, 'logo')"
                :before-upload="upLoadImgBefore"
                style="height: 110px"
              >
                <div class="uploadBox" v-if="baseInfoForm.logo_url === ''">
                  <i class="el-icon-plus avatar-uploader-icon"></i>
                  <div>上传店铺LOGO</div>
                </div>
                <img
                  style="width: 110px; height: 110px"
                  :src="baseInfoForm.logo_url"
                  v-if="baseInfoForm.logo_url !== ''"
                />
              </el-upload>
              <span class="explain">*建议尺寸640*640像素</span>
            </div>
          </el-form-item>
          <el-form-item label="店铺首页banner图片设置：" prop="banner_url">
            <div class="imgsBox" style="align-items: flex-start">
              <div
                v-for="(item, index) in bannerImgs"
                :key="index"
                class="imgBox"
                :style="index === 0 ? 'height:110px;' : ''"
              >
                <img :src="item" class="photo"/>
                <img
                  src="/img/sctp@2x.png"
                  class="imgDel"
                  @click.stop="imgDel(index, 'banner')"
                />
                <div style="display: flex; justify-content: center">
                  <el-button
                    class="imgButton"
                    type="primary"
                    size="mini"
                    @click="moveImg('front', index)"
                    v-if="index != 0"
                  >前移
                  </el-button>
                  <el-button
                    class="imgButton"
                    type="primary"
                    size="mini"
                    @click="moveImg('back', index)"
                    v-if="index != bannerImgs.length - 1"
                  >后移
                  </el-button>
                </div>
              </div>
              <el-upload
                :action="MixinUploadApi"
                :show-file-list="false"
                :on-success="
                  (res, file) => upLoadImgSuccess(res, file, 'banner')
                "
                :before-upload="upLoadImgBefore"
                v-if="bannerImgs.length < 5"
              >
                <div class="uploadBox">
                  <i class="el-icon-plus avatar-uploader-icon"></i>
                  <div>上传banner图片</div>
                </div>
              </el-upload>
            </div>
            <span class="explain">*最多上5张</span>
            <span class="explain">*建议尺寸640*640像素</span>
          </el-form-item>
          <el-form-item label="店铺简介：" prop="intro">
            <el-input
              type="textarea"
              resize="none"
              :rows="3"
              :maxlength="200"
              style="width: 400px"
              v-model="baseInfoForm.intro"
              placeholder="请输入店铺简介,最多输入200字"
            ></el-input>
          </el-form-item>
        </div>
        <el-button
          type="primary"
          style="margin: 0 auto; display: block"
          @click="submit"
        >保存并提交
        </el-button
        >
      </div>
    </el-form>

    <el-dialog
      :title="dialogTitle"
      :visible.sync="centerDialogVisible"
      width="30%"
      center
    >
      <div
        class="dialogContent"
        v-if="dialogTitle === '添加导师信息' || dialogTitle === '编辑导师信息'"
      >
        <div class="left">
          <div>导师姓名：</div>
          <div>导师亮点：</div>
          <div>导师图片：</div>
          <div style="margin-top: 110px">导师介绍：</div>
        </div>
        <div class="right">
          <div class="flex">
            <el-input
              maxlength="32"
              placeholder="请输入导师姓名,最多不超过32字。"
              v-model="teacher.teacher_name"
            ></el-input>
          </div>
          <div class="flex">
            <el-input
              maxlength="7"
              placeholder="请输入导师亮点,最多不超过7字。"
              v-model="teacher.teacher_light"
            ></el-input>
          </div>
          <div>
            <el-upload
              :action="MixinUploadApi"
              :show-file-list="false"
              :on-success="
                (res, file) => upLoadImgSuccess(res, file, 'teacher')
              "
              :before-upload="upLoadImgBefore"
              style="height: 110px"
            >
              <div class="uploadBox" v-if="teacher.teacher_pic === ''">
                <i class="el-icon-plus avatar-uploader-icon"></i>
                <div>上传导师图片</div>
              </div>
              <img
                style="width: 110px; height: 110px"
                :src="teacher.teacher_pic"
                v-if="teacher.teacher_pic"
              />
            </el-upload>
            <span class="explain">*建议尺寸640*640像素</span>
          </div>
          <div style="margin-top: 10px; flex: 1">
            <el-input
              type="textarea"
              resize="none"
              :rows="3"
              :maxlength="200"
              v-model="teacher.teacher_intro"
              placeholder="请输入导师介绍,最多输入200字"
            ></el-input>
          </div>
        </div>
      </div>
      <div class="delContent" v-if="dialogTitle === '删除导师信息'">
        确定删除导师吗？
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="ok">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import * as API_teacher from "@/api/teacher";
import * as API_shopSettings from "@/api/shopSettings";
import AddressSelector from "@/components-new/address-selector/address-selector";
import {quillEditor} from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

export default {
  components: {
    AddressSelector,
    quillEditor,
  },
  data() {
    return {
      /**  加载中。。。 */
      loading: false,

      /** 富文本框配置 */
      editorOption: {
        placeholder: "请输入...",
        modules: {
          toolbar: [
            ["bold", "italic", "underline"], // toggled buttons
            ["blockquote"],
          ],
        },
      },
      /**  编辑或添加导师弹框 */
      centerDialogVisible: false,
      dialogTitle: "",

      /**  基本信息提交表单 */
      baseInfoForm: {},

      /**  店铺表单验证 */
      rules: {
        entity_province_id: [
          {
            required: true,
            message: "请选择商户实体店铺地址",
            trigger: "blur",
          },
        ],
        entity_addr: [
          {
            required: true,
            message: "请填写商户实体店铺详细地址",
            trigger: "blur",
          },
        ],
        entity_intro: [
          {
            required: true,
            message: "请填写实体店铺分店/连锁店信息",
            trigger: "blur",
          },
        ],
        showList2: [
          {
            required: true,
            message: "请选择分店信息是否显示在商户首页",
            trigger: "blur",
          },
        ],

        shopQualification: [
          {
            required: true,
            message: "请上传店铺资质图片",
            trigger: "blur",
          },
        ],
        showList0: [
          {
            required: true,
            message: "请选择商户资质是否显示在商户首页",
            trigger: "blur",
          },
        ],

        workday_open: [
          {
            required: true,
            message: "请选择工作日营业时间",
            trigger: "blur",
          },
        ],
        weekend_open: [
          {
            required: true,
            message: "请选择周末营业时间",
            trigger: "blur",
          },
        ],
        showList3: [
          {
            required: true,
            message: "请选择店铺营业时间是否显示在商户首页",
            trigger: "blur",
          },
        ],

        showList1: [
          {
            required: true,
            message: "请选择师资力量是否显示在商户首页",
            trigger: "blur",
          },
        ],

        phone1: [
          {
            required: true,
            message: "请填写第一个客服电话",
            trigger: "blur",
          },
        ],
        phone2: [
          {
            required: true,
            message: "请填写第二个客服电话",
            trigger: "blur",
          },
        ],
        logo_url: [
          {
            required: true,
            message: "请上传店铺LOGO",
            trigger: "blur",
          },
        ],
        banner_url: [
          {
            required: true,
            message: "请上传店铺首页banner图片",
            trigger: "blur",
          },
        ],
        intro: [
          {
            required: true,
            message: "请填写店铺简介",
            trigger: "blur",
          },
        ],
      },
      hourList: [
        "00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12",
        "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"
      ],
      minuteList: [
        "00", "15", "30", "45"
      ],

      /**  导师列表 */
      teacherBox: [],

      /**  资质图片列表 */
      qualificationsImgs: [],

      /**  banner图片列表 */
      bannerImgs: [],

      /**  工作日营业时间 */
      businessHours: {
        start_hour: '00',
        start_minute: '00',
        end_hour: '00',
        end_minute: '00',
        start_time: '',
        end_time: ''
      },

      /**  工作日营业时间 */
      weekendHours: {
        start_hour: '00',
        start_minute: '00',
        end_hour: '00',
        end_minute: '00',
        start_time: '',
        end_time: ''
      },

      // 新建或编辑导师信息
      teacher: {
        teacher_name: "",
        teacher_light: "",
        teacher_pic: "",
        teacher_intro: "",
      },

      // 选中导师信息
      teacherChoose: "",

      /**  地址回显 */
      address: [],
    };
  },
  mounted() {
    // 查询导师列表
    this.POST_activeTeacherShopList();

    // 获取店铺设置
    this.GET_sellerShopsGetShopSetting();
  },
  activated() {
    // 查询导师列表
    this.POST_activeTeacherShopList();

    // 获取店铺设置
    this.GET_sellerShopsGetShopSetting();
  },
  methods: {
    /**  新增导师 */
    POST_activeTeacherAdd() {
      API_teacher.activeTeacherAdd(this.teacher).then((res) => {
        this.$message.success("添加导师信息成功！");
        this.teacher = {
          teacher_name: "",
          teacher_light: "",
          teacher_pic: "",
          teacher_intro: "",
        };
        this.POST_activeTeacherShopList();
        // console.log(res);
      });
    },

    /**  查询导师列表 */
    POST_activeTeacherShopList() {
      API_teacher.activeTeacherShopList().then((res) => {
        this.teacherBox = res;
        // console.log(res);
      });
    },

    /**  删除导师 */
    DELTET_activeTeacherDel(id) {
      API_teacher.activeTeacherDel(id).then((res) => {
        this.$message.success("删除导师信息成功！");
        this.teacher = {
          teacher_name: "",
          teacher_light: "",
          teacher_pic: "",
          teacher_intro: "",
        };
        this.POST_activeTeacherShopList();
        console.log(res);
      });
    },

    /**  修改导师信息 */
    POST_activeTeacherEdit() {
      API_teacher.activeTeacherEdit(this.teacher).then((res) => {
        this.$message.success("编辑导师信息成功！");
        this.teacher = {
          teacher_name: "",
          teacher_light: "",
          teacher_pic: "",
          teacher_intro: "",
        };
        console.log(res);
      });
    },

    /**  获取店铺设置 */
    GET_sellerShopsGetShopSetting() {
      API_shopSettings.sellerShopsGetShopSetting().then((res) => {
        this.baseInfoForm = res || {};

        // 地址回显
        this.address = [
          this.baseInfoForm.entity_province_id,
          this.baseInfoForm.entity_city_id,
          this.baseInfoForm.entity_county_id,
          this.baseInfoForm.entity_town_id,
        ].filter((id) => !!id);

        // 是否显示在商户首页4个选项回显
        this.baseInfoForm.home_show?.split(",").forEach((item, index) => {
          if (index === 0) {
            this.$set(this.baseInfoForm, "showList0", Number(item));
          } else if (index === 1) {
            this.$set(this.baseInfoForm, "showList1", Number(item));
          } else if (index === 2) {
            this.$set(this.baseInfoForm, "showList2", Number(item));
          } else if (index === 3) {
            this.$set(this.baseInfoForm, "showList3", Number(item));
          }
        });

        // 资质图片回显
        if (this.baseInfoForm.shop_qualification) {
          this.qualificationsImgs = this.baseInfoForm.shop_qualification.split(
            ","
          );
        }
        // banner图片回显
        this.bannerImgs = this.baseInfoForm.banner_url.split(",");

        // 营业时间回显
        this.businessHours.start_hour = this.baseInfoForm.workday_open.split(':')[0]
        this.businessHours.start_minute = this.baseInfoForm.workday_open.split(':')[1]
        this.businessHours.end_hour = this.baseInfoForm.workday_close.split(':')[0]
        this.businessHours.end_minute = this.baseInfoForm.workday_close.split(':')[1]
        this.weekendHours.start_hour = this.baseInfoForm.weekend_open.split(':')[0]
        this.weekendHours.start_minute = this.baseInfoForm.weekend_open.split(':')[1]
        this.weekendHours.end_hour = this.baseInfoForm.weekend_close.split(':')[0]
        this.weekendHours.end_minute = this.baseInfoForm.weekend_close.split(':')[1]
        // console.log(res);
      });
    },

    /**  店铺设置 */
    PUT_sellerShopsShopSetting() {
      API_shopSettings.sellerShopsShopSetting(this.baseInfoForm).then((res) => {
        // console.log(res);
        this.$message.success("提交成功！");
      });
    },

    /**  上传图片前校验 */
    upLoadImgBefore(file) {
      const isImg = file.type.indexOf("image") > -1;
      if (!isImg) {
        this.$message.error("只能上传图片");
      }
      return isImg;
    },

    /**  上传图片 */
    upLoadImgSuccess(res, file, type) {
      if (type === "qualifications") {
        this.qualificationsImgs.push(file.response.url);
        this.baseInfoForm.shopQualification = this.qualificationsImgs.join(",");
      } else if (type === "logo") {
        this.baseInfoForm.logo_url = file.response.url;
      } else if (type === "banner") {
        this.bannerImgs.push(file.response.url);
        this.baseInfoForm.banner_url = this.bannerImgs.join(",");
      } else if (type === "teacher") {
        this.teacher.teacher_pic = file.response.url;
      }
    },

    /**  删除图片 */
    imgDel(index, type) {
      if (type === "qualifications") {
        this.qualificationsImgs.splice(index, 1);
        this.baseInfoForm.shopQualification = this.qualificationsImgs.join(",");
      } else if (type === "banner") {
        this.bannerImgs.splice(index, 1);
        this.baseInfoForm.banner_url = this.bannerImgs.join(",");
      }
    },

    /**  图片移动 */
    moveImg(type, index) {
      const temp = this.baseInfoForm.imgList3[index];
      if (type === "back") {
        this.bannerImgs.splice(index, 1);
        this.bannerImgs.splice(index + 1, 0, temp);
      } else if (type === "front") {
        this.bannerImgs.splice(index, 1);
        this.bannerImgs.splice(index - 1, 0, temp);
      }
    },

    /**  选择区域 */
    handleChangeArea(val) {
      const fields = ["province", "city", "county", "town"];

      fields.forEach((field) => {
        this.baseInfoForm[`entity_${field}_id`] = 0;
        this.baseInfoForm[`entity_${field}`] = "";
      });

      val.forEach((item, index) => {
        this.baseInfoForm[`entity_${fields[index]}_id`] = item.id;
        this.baseInfoForm[`entity_${fields[index]}`] = item.local_name;
      });
    },

    /**  营业时间选择 */
    businessTime(type, time_type) {
      if (type === "business") {
        if (time_type === 'start') {
          this.baseInfoForm.workday_open = this.businessHours.start_hour + ":" + this.businessHours.start_minute
        } else {
          this.baseInfoForm.workday_close = this.businessHours.end_hour + ":" + this.businessHours.end_minute
        }
      } else if (type === "weekend") {
        if (time_type === 'start') {
          this.baseInfoForm.weekend_open = this.weekendHours.start_hour + ":" + this.weekendHours.start_minute
        } else {
          this.baseInfoForm.weekend_close = this.weekendHours.end_hour + ":" + this.weekendHours.end_minute
        }
      }
    },

    /**  导师添加、编辑和删除 */
    teacherAddAndEdit(type, row) {
      this.teacher = {
        teacher_name: "",
        teacher_light: "",
        teacher_pic: "",
        teacher_intro: "",
      };
      if (row) {
        this.teacher = row;
      }
      if (type === "add") {
        this.dialogTitle = "添加导师信息";
        this.centerDialogVisible = true;
      } else if (type === "edit") {
        this.dialogTitle = "编辑导师信息";
        this.centerDialogVisible = true;
      } else if (type === "del") {
        this.dialogTitle = "删除导师信息";
        this.centerDialogVisible = true;
      }
    },

    /**  导师添加和编辑确认 */
    ok() {
      if (this.dialogTitle === "添加导师信息") {
        this.POST_activeTeacherAdd();
      } else if (this.dialogTitle === "编辑导师信息") {
        this.POST_activeTeacherEdit();
      } else if (this.dialogTitle === "删除导师信息") {
        this.DELTET_activeTeacherDel(this.teacher.id);
      }
      this.centerDialogVisible = false;
    },

    /**  是否在首页显示单选框变化 */
    showListChange() {
      this.baseInfoForm.home_show = "";
      this.baseInfoForm.home_show += this.baseInfoForm.showList0;
      this.baseInfoForm.home_show += "," + this.baseInfoForm.showList1;
      this.baseInfoForm.home_show += "," + this.baseInfoForm.showList2;
      this.baseInfoForm.home_show += "," + this.baseInfoForm.showList3;
    },

    /**  提交表单 */
    submit() {
      this.$refs.baseInfoForm.validate((valid) => {
        if (valid) {
          if (
            this.baseInfoForm.entity_province_id === 0 &&
            this.baseInfoForm.entity_city_id === 0 &&
            this.baseInfoForm.entity_county_id === 0 &&
            this.baseInfoForm.entity_town_id === 0
          ) {
            this.baseInfoForm.entity_province_id = "";
            this.baseInfoForm.entity_city_id = "";
            this.baseInfoForm.entity_county_id = "";
            this.baseInfoForm.entity_town_id = "";
          }
          this.PUT_sellerShopsShopSetting();
        } else {
          this.$message.error("请填写完整修改密码表单！");
          return false;
        }
      });
    },
  },
};
</script>

<style type='text/scss' lang='scss' scoped>
/*编辑基本信息*/
.el-form {
  padding-bottom: 80px;

  .el-form-item {
    width: 100%;
    text-align: left;
  }
}

/*平铺*/
div.base-info-item > div {
  margin-left: 5%;
}

div.base-info-item {
  h4 {
    margin-bottom: 10px;
    padding: 0 10px;
    border: 1px solid #ddd;
    background-color: #f8f8f8;
    font-weight: bold;
    color: #333;
    font-size: 14px;
    line-height: 40px;
    text-align: left;
  }
}

/deep/ .el-form-item {
  width: auto !important;

  .el-form-item__label {
    width: 280px !important;
  }
}

/deep/ .quill-editor {
  width: 80% !important;

  .ql-container {
    height: 100px !important;
  }
}

/* 上传区域图标 */
.uploadBox {
  width: 110px;
  height: 110px;
  border: 1px dashed rgba(221, 221, 221, 1);
  border-radius: 4px;
  background: rgba(247, 250, 250, 1);

  i {
    display: block;
    margin: 26px auto 12px;
    color: #999;
    font-size: 33px;
  }

  div {
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    color: #999;
  }
}

/* 图片盒子 */
.imgsBox {
  width: 660px;
  background: rgba(246, 246, 246, 1);
  border-radius: 8px;
  padding: 24px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .imgBox {
    width: 110px;
    height: 150px;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    position: relative;

    .photo {
      width: 110px;
      height: 110px;
      background: #fff;
    }

    .imgDel {
      width: 24px;
      height: 24px;
      position: absolute;
      right: 5px;
      top: 5px;
      cursor: pointer;
    }

    .imgButton {
      color: #999;
      border: 1px solid #aaa;
      background: #fff;
      margin-right: 10px;
      font-size: 14px;
      padding: 4px 5px;
    }

    .imgButton:hover {
      color: #008080;
      border: 1px solid #008080;
    }

    .teacherName {
      text-align: center;
      font-size: 14px;
      line-height: 20px;
    }

    .edit {
      position: absolute;
      width: 110px;
      height: 110px;
      background: rgba(0, 0, 0, 0.3);
      font-size: 14px;
      line-height: 110px;
      text-align: center;
      left: 0;
      top: 0;
      cursor: pointer;
      display: none;
    }
  }

  .imgBox:hover {
    .edit {
      display: block;
    }
  }
}

/* 表单说明 */
.explain {
  display: block;
  font-size: 14px;
  line-height: 14px;
  color: #888;
  margin-top: 10px;
}

/* 弹框盒子 */
.dialogContent {
  display: flex;

  .left {
    div {
      line-height: 32px;
      font-size: 16px;
      text-align: right;
      margin-bottom: 10px;
    }
  }

  .right {
    flex: 1;

    .flex {
      height: 32px;
      line-height: 32px;
      margin-bottom: 10px;
    }
  }
}

.delContent {
  line-height: 32px;
  font-size: 16px;
  text-align: center;
}

.width {
  width: 400px;
}
</style>

<style type='text/scss' lang='scss'>
.shop-settings {
  .el-form-item__label {
    word-break: break-all;
  }

  .quill-editor {
    .ql-toolbar {
      display: none;
    }

    .ql-container {
      background: #fff;
      border-top: 1px solid #ccc !important;
    }
  }
}
</style>
