/**
 * 店铺设置相关API
 */

import request from '@/utils/request'

/**
 * 获取店铺设置
 * @param
 */
export function sellerShopsGetShopSetting() {
  return request({
    url: '/seller/shops/getShopSetting',
    method: 'get',
    loading: false
  })
}

/**
 * 店铺设置
 * @param params
 */
export function sellerShopsShopSetting(params) {
  return request({
    url: '/seller/shops/shopSetting',
    method: 'put',
    loading: false,
    params
  })
}
